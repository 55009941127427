import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function TermsPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 45, fontWeight: "700", textAlign: "center" }]}>{`Terms of Use`}</Text>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 42, fontWeight: "600", textAlign: "center" }]}>{`Effective as of Jan 1, 2024`}</Text>

            <Text style={styles.description}>{`Welcome to GetAwayStressFree.com (referred to herein as ""GetAwayStressFree.com"", “we”, “our” or “us”). We're thrilled to have you here! Before you embark on your journey through our website(s) and app(s), let's go over some important guidelines. These Terms of Use outline the rules of engagement between you and GetAwayStressFree.com, so please take a moment to read through them carefully.`}</Text>
            <Text style={styles.title}>{`1. Eligibility and Registration:`}</Text>
            <Text style={styles.description}>{`To access certain features of our Services, you'll need to register for an account. By registering for an account, you agree that all registration information is legal, accurate, and complete. You also authorize us, directly or through third parties, to verify your account information. Your privacy is important to us, and our Privacy Policy explains how we handle your information.`}</Text>
            <Text style={styles.description}>{`You agree not to use false identities or impersonate any other person or use a username or password that you are not authorized to use. We reserve the right to require you to change your username for any reason at any time.`}</Text>
            <Text style={styles.description}>{`You are responsible for safeguarding and maintaining the confidentiality of your account information. Please don't share your password with anyone. Notify us immediately if you suspect any unauthorized use of your account.`}</Text>
            <Text style={styles.title}>{`2. License:`}</Text>
            <Text style={styles.description}>{`We grant you a limited, personal, non-exclusive, revocable, non-sublicensable, non-transferable license to use the Services, contingent upon your compliance with these Terms of Use and any additional terms and conditions provided by us. This license is for personal use only and does not grant any commercial use rights.`}</Text>
            <Text style={styles.description}>{`You agree not to export, re-export, sell, or otherwise transfer the software provided through the Services to any prohibited parties or destinations.`}</Text>
            <Text style={styles.title}>{`3. Proprietary Rights:`}</Text>
            <Text style={styles.description}>{`The Services and all content therein are protected by intellectual property laws. We own and control all rights to the Services and associated content. You agree not to reproduce, modify, adapt, distribute, or exploit any content from the Services without our permission.`}</Text>
            <Text style={styles.description}>{`We reserve the right to remove any content from the Services at our discretion.`}</Text>
            <Text style={styles.title}>{`4. Use of the Services:`}</Text>
            <Text style={styles.description}>{`You agree to use the Services only for their intended purposes and not to engage in any activity that could harm or interfere with the Services. This includes not introducing malicious software or attempting to gain unauthorized access to accounts or networks.`}</Text>
            <Text style={styles.description}>{`You acknowledge that you are solely responsible for any breach of your obligations described herein.`}</Text>
            <Text style={styles.title}>{`5. Termination or Suspension:`}</Text>
            <Text style={styles.description}>{`We reserve the right to modify, discontinue, or terminate the Services at any time without notice. We may also suspend or terminate your account if we suspect you have violated these Terms of Use or engaged in illegal activities.`}</Text>
            <Text style={styles.title}>{`6. Miscellaneous:`}</Text>
            <Text style={styles.description}>{`If any provision of these Terms of Use is found to be invalid, the remaining provisions will still apply. These Terms of Use constitute the entire agreement between you and GetAwayStressFree.com.`}</Text>
            <Text style={styles.description}>{`If you have any questions, feel free to contact us at go@GetAwayStressFree.com.`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50,
            fontFamily: "Dongle-Regular",
        },
        title: {
            color: "#0B3C49",
            fontSize: 40,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "Dongle-Regular",
            lineHeight: 30,
        },
        description: {
            color: "#0B3C49",
            fontSize: 34,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "Dongle-Regular",
            lineHeight: 30,
        },
    }
)
export default TermsPage;
