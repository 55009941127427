// import React from 'react';
// import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { AppRegistry } from 'react-native';
import regular from './assets/fonts/Dongle-Regular.ttf';
import bold from './assets/fonts/Dongle-Bold.ttf';
import light from './assets/fonts/Dongle-Light.ttf';

const regularFontStyles = `@font-face {
  src: url(${regular});
  font-family: 'Dongle-Regular';
}`;

const lightFontStyles = `@font-face {
  src: url(${light});
  font-family: 'Dongle-Light';
}`;

const boldFontStyles = `@font-face {
  src: url(${bold});
  font-family: 'Dongle-Bold';
}`;
// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = regularFontStyles;
} else {
  style.appendChild(document.createTextNode(regularFontStyles));
  style.appendChild(document.createTextNode(lightFontStyles));
  style.appendChild(document.createTextNode(boldFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

AppRegistry.registerComponent('App', () => App);

AppRegistry.runApplication('App', {

  rootTag: document.getElementById('root')

});

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
