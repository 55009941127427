import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function PrivacyPage({ navigation }) {


    return (
        <View style={styles.root}>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 45, fontWeight: "700", textAlign: "center" }]}>{`Privacy Policy `}</Text>
            <Text style={styles.description}>{`Welcome to GetAwayStressFree.com (""GetAwayStressFree.com"", ""we"", ""our"", or ""us""). Protecting your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, including any mobile applications and online services (collectively, the ""Services""). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Services.`}</Text>
            <Text style={styles.title}>{`Information We Collect`}</Text>
            <Text style={styles.description}>{`We may collect personal information that you voluntarily provide to us when you interact with the Services, such as when you fill in a contact form or communicate with us through other means. The types of personal information we may collect include your name, email address, phone number, and any other information you choose to provide.`}</Text>
            <Text style={styles.title}>{`How We Use Your Information`}</Text>
            <Text style={styles.description}>{`We may use the information we collect from you to:`}</Text>
            <Text style={styles.subDescription}>{`\t- Respond to your inquiries and provide customer support.`}</Text>
            <Text style={styles.subDescription}>{`\t- Send you promotional communications, such as newsletters, special offers, and other marketing materials.`}</Text>
            <Text style={styles.subDescription}>{`\t- Improve the Services and develop new features and offerings.`}</Text>
            <Text style={styles.subDescription}>{`\t- Analyze trends, administer the Services, and gather demographic information.`}</Text>
            <Text style={styles.subDescription}>{`\t- Comply with legal obligations and enforce our terms and policies.`}</Text>
            <Text style={styles.title}>{`Disclosure of Your Information`}</Text>
            <Text style={styles.description}>{`We may share your information with third-party service providers who perform services on our behalf, such as hosting providers, email service providers, and customer support providers. We may also disclose your information if required by law or in response to a subpoena, court order, or other legal process.`}</Text>
            <Text style={styles.title}>{`Your Choices`}</Text>
            <Text style={styles.description}>{`You have the right to opt out of receiving promotional communications from us by following the instructions provided in such communications. Please note that even if you opt out of receiving promotional communications, we may still send you non-promotional communications, such as those related to your inquiries or transactions with us.`}</Text>
            <Text style={styles.title}>{`Security`}</Text>
            <Text style={styles.description}>{`We take reasonable measures to protect your information from unauthorized access, use, and disclosure. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.`}</Text>
            <Text style={styles.title}>{`Children's Privacy`}</Text>
            <Text style={styles.description}>{`The Services are not directed to children under the age of 18, and we do not knowingly collect personal information from children under the age of 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.`}</Text>
            <Text style={styles.title}>{`Changes to This Privacy Policy`}</Text>
            <Text style={styles.description}>{`We reserve the right to update or change this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on the Services. Your continued use of the Services after the posting of the revised Privacy Policy will constitute your consent to the changes.`}</Text>
            <Text style={styles.title}>{`Contact Us`}</Text>
            <Text style={styles.description}>{`If you have any questions or concerns about this Privacy Policy, please contact us at go@GetAwayStressFree.com.`}</Text>
            <Text style={styles.description}>{`By using the Services, you consent to the collection, use, and disclosure of your information in accordance with this Privacy Policy.`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        title: {
            color: "#0B3C49",
            fontSize: 40,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "Dongle-Regular",
            lineHeight: 30,
        },
        description: {
            color: "#0B3C49",
            fontSize: 34,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
            fontFamily: "Dongle-Regular",
            lineHeight: 30,
        },
        subDescription: {
            color: "#0B3C49",
            fontSize: 30,
            fontWeight: "400",
            position: "relative",
            fontFamily: "Dongle-Regular",
            lineHeight: 30,
        },
    }
)

export default PrivacyPage;
