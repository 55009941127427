import { View, Text, StyleSheet, ImageBackground, Button, TextInput } from 'react-native';
import { useState, useCallback } from 'react';
import Footer from '../components/Footer';
import { isMobile } from '../helpers/deviceUtils';
import { useNavigate } from 'react-router-dom';
import { Image, Pressable } from 'react-native-web';
import AutoInsuranceTabs from './autoInsurance';

const isMobileDevice = isMobile();


function AutoHomePage({ navigation }) {
    const styles = isMobileDevice ? mobileStyles : desktopStyles;
    const [showInfoView, setShowInfoView] = useState(false);
    const navigate = useNavigate();

    const onStartButtonPress = useCallback(() => {
        setShowInfoView(true)
    }, [navigate])

    return (
        <View style={styles.root}>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <Text style={styles.title}>{messages.title}</Text>
                    <Image source={require('../assets/header-icon.png')} style={styles.headerIcon} />
                </View>
            </View>

            <View style={styles.contentView}>
                <ImageBackground source={require('../assets/background.png')}
                    style={styles.image} />

                {showInfoView ? <AutoInsuranceTabs /> : <View style={styles.mainContent} >
                    <Text style={styles.description}>{messages.description}</Text>
                    <Text style={styles.subDescription}>{messages.subDescription}</Text>


                    <Pressable style={[styles.buttonView]}
                        onPress={onStartButtonPress}>
                        <Text style={[styles.buttonContent]}>{messages.seeQuotes}</Text>
                    </Pressable>
                </View>}
            </View>
            <Footer navigation={navigation} style={styles.footer}>

            </Footer>

        </View>
    );
}

const baseStyles = StyleSheet.create(
    {
        root: {
            backgroundColor: '#91E9EC',
        },
        header: {
            height: window.innerHeight * 0.1,
            backgroundColor: 'white',
            justifyContent: "center"
        },
        headerIcon: {
            width: 400,
            height: "90%",
            resizeMode: 'contain'
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "500",
            fontFamily: "Dongle-Regular",
        },
        contentView: {
            height: window.innerHeight * 0.9,
            width: "100%",
        },
        image: {
            width: "100%",
            height: '100%',
            resizeMode: 'cover',
        },
        description: {
            color: "#111111",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Dongle-Regular",
            width: "90%"
        },
        subDescription: {
            color: "#111111",
            fontWeight: "400",
            textAlign: "center",
            fontFamily: "Dongle-Light",
            width: "90%"
        },
        error: {
            color: "red",
            fontWeight: "400",
            lineHeight: 24
        },
        inputView: {
            flexDirection: "row",
            marginTop: 40
        },
        textInput: {
            backgroundColor: "white",
            borderRadius: 10,
            fontWeight: 400,
            fontFamily: "Dongle-Regular",
            padding: 15,
        },
        footer: {
            position: "absolute",
            marginTop: window.innerHeight * 0.94,
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
        },
        buttonView: {
            backgroundColor: "rgba(0,151,178,0.45)",
            marginTop: 20,
            position: "relative",
            borderRadius: 40,
            paddingVertical: 5,
            paddingHorizontal: 15,
        },
        buttonContent: {
            color: "#191C1F",
            fontSize: 30,
            lineHeight: "100%",
            fontWeight: "600",
            padding: 10,
            fontFamily: "Dongle-Regular",
        },
    }
)

const desktopStyles = StyleSheet.create(
    {
        ...baseStyles,
        header: {
            ...baseStyles.header,
            width: '100%',
        },
        headerContent: {
            marginLeft: 215,
            flexDirection: "row",
            height: "100%",
            alignItems: "center",
        },
        title: {
            ...baseStyles.title,
            fontSize: 50,
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "center",
            alignItems: "center",
        },
        mainContent: {
            width: "50%",
            minWidth: 500,
            position: "absolute",
            backgroundColor: "white",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            padding: 20
        },
        description: {
            ...baseStyles.description,
            fontSize: 50,
            lineHeight: 45
        },
        subDescription: {
            ...baseStyles.subDescription,
            fontSize: 35,
            lineHeight: 40
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 12,
            width: 220
        },
    }
)


const mobileStyles = StyleSheet.create(
    {
        ...baseStyles,
        root: {
            ...baseStyles.root,
            height: "100%",
            width: '100%',
        },
        header: {
            ...baseStyles.header,
            width: window.innerWidth,
        },
        headerContent: {
            marginLeft: "10%",
            flexDirection: "row",
            alignItems: "center"
        },
        headerIcon: {
            ...baseStyles.headerIcon,
            width: 320,
            height: "90%",
            resizeMode: 'contain',
            marginRight: 20,
        },
        title: {
            ...baseStyles.title,
            fontSize: 28,
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "flex-start"
        },
        mainContent: {
            marginTop: 65,
            marginLeft: "10%",
            width: "80%",
            position: "absolute",
            backgroundColor: "white",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            padding: 20
        },
        description: {
            ...baseStyles.description,
            fontSize: 40,
            lineHeight: 45
        },
        subDescription: {
            ...baseStyles.description,
            marginTop: 10,
            fontSize: 30,
            lineHeight: 30,
            fontWeight: "400"
        },
        error: {
            ...baseStyles.error,
            fontSize: 20,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 10,
            width: "60%"
        },
    }
)

const messages = {
    title: "Get Away Stress Free",
    description: "Let's get planning your next stress free holiday!",
    subDescription: "We need just a few small details about your party to get our team started and working on the best options for your dream vacation!\nLet's go!",
    seeQuotes: "COMPLETE IN 30 SEC",
}

export default AutoHomePage;
